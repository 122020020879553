<template>
    <div class="bin_form">
        <a-form-model
            ref="authForm"
            :model="form"
            layout="vertical"
            autocomplete="off"
            :rules="rules">
            <a-form-model-item 
                prop="contractor_bin"
                class="w-full mb-2"
                label="БИН организации">
                <a-input 
                    size="large"
                    autocomplete="off"
                    @pressEnter="formSubmit()"
                    class="w-full"
                    :maxLength="12"
                    @change="changeBIN"
                    @blur="getContractorNameByBIN"
                    v-model="form.contractor_bin" />
            </a-form-model-item>
            <a-form-model-item 
                prop="contractor_name"
                class="mb-2"
                label="Наименование организации">
                <a-input 
                    size="large"
                    autocomplete="off"
                    class="w-full"
                    disabled
                    @pressEnter="formSubmit()"
                    type="textarea"
                    v-model="form.contractor_name" />
            </a-form-model-item>
            <div class="buttons_grid">
                <a-button 
                    size="large"
                    @click="closeDrawer">
                    Назад
                </a-button>
                <a-button
                    class="button_next"
                    type="primary"
                    ghost
                    :loading="loading"
                    size="large"
                    @click="formSubmit()">
                    Далее
                </a-button>
            </div>
        </a-form-model>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    props: {
        saveForm: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        authConfig() {
            return this.$store.state.user.authConfig
        },
        authTypePlaceholder() {
            if(this.authConfig?.registerType) {
                if(this.authConfig?.registerType === 'all') {
                    return 'Ваш телефон / E-mail'
                } else {
                    if(this.authConfig?.registerType === 'phone') {
                        return 'Ваш телефон'
                    }
                }
            }
            return 'Ваш E-mail'
        }
    },
    data() {
        return {
            loading: false,
            form: {
                contractor_bin: '',
                contractor_name: '',
                contractor_id: ''
            },
            rules: {
                contractor_bin: [
                    { min: 12, max: 12, message: 'БИН должен состоять из 12 символов', trigger: 'blur' },
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' },
                ]
            }
        }
    },
    created() {
        this.form.contractor_bin = JSON.parse(JSON.stringify(this.saveForm.contractor_bin))
        this.form.contractor_id = JSON.parse(JSON.stringify(this.saveForm.contractor_id))
        this.form.contractor_name = JSON.parse(JSON.stringify(this.saveForm.contractor_name))
        if (this.form.contractor_bin) {
            this.getContractorNameByBIN()
        }
    },
    methods: {
        changeBIN(event) {
            if (event?.target?.value?.length === 12)  {
                this.getContractorNameByBIN()
            }
        },
        formSubmit() {
            if (this.form?.contractor_bin?.length === 12 && 
                !this.form.contractor_id &&
                !this.loading
            ) {
                this.$message.error('Указанный БИН отсутствует в базе')
                return 0
            }
            
            this.$refs.authForm.validate(async valid => {
                if (valid) {
                    this.saveForm.contractor_bin = this.form.contractor_bin
                    this.saveForm.contractor_id = this.form.contractor_id

                    this.$store.commit('user/SET_REG_STEP', 2)
                }
            })
        },
        async getContractorNameByBIN() {
            try {
                this.loading = true
                const { data } = await this.$http(`/catalogs/contractors_by_inn/`, {
                    params: {
                        text: this.form.contractor_bin
                    }
                })
                if (data?.length) {
                    this.form.contractor_name = data[0].name
                    this.form.contractor_id = data[0].id
                } else {
                    this.form.contractor_name = ''
                    this.form.contractor_id = ''
                }
            } catch(error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        },
        closeDrawer() {
            eventBus.$emit(`close_registration`)
        }
    }
}
</script>

<style lang="scss" scoped>
$breakpoint-1: 828px;
.buttons_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.button_next {
    grid-column: span 2 / span 2;
    @media (max-width: $breakpoint-1) {
        grid-column: span 1 / span 1;
    }
}
.bin_form {
    &::v-deep {
        .ant-input,
        .ant-btn {
            height: 50px;
        }
    }
}
</style>